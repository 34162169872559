<template>
  <div class="alita-home">
    <tableView
      :data-status="99"
      :show-status="true"
    />
  </div>
</template>

<script>
  import tableView from '@/components/alitatech/apply/table'

  export default {
    name: "apply",
    components: {
      tableView
    },
  }
</script>

<style scoped>
.title {
  padding-left: 20px;
  font-size: 20px;
  font-weight: bold;
}
</style>
